import request from '@/utils/request'
import AMapLoader from '@amap/amap-jsapi-loader'

export async function getCurrentPosition () {
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position.coords)
          resolve({
            code: 0,
            position: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          })
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log('定位失败,用户拒绝请求地理定位')
              resolve({
                code: 401,
                message: '定位失败,用户拒绝请求地理定位'
              })
              break
            case error.POSITION_UNAVAILABLE:
              console.log('定位失败,位置信息是不可用')
              resolve({
                code: 402,
                message: '定位失败,位置信息是不可用'
              })
              break
            case error.TIMEOUT:
              console.log('定位失败,请求获取用户位置超时')
              resolve({
                code: 403,
                message: '定位失败,请求获取用户位置超时'
              })
              break
            default:
              console.log('定位失败,定位系统失效')
              resolve({
                code: 404,
                message: '定位失败,定位系统失效'
              })
              break
          }
        }
      )
    } else {
      console.log('浏览器不支持地理定位')
      resolve({
        code: 400,
        message: '浏览器不支持地理定位'
      })
    }
  })
}

export async function getSelectedCityInfo () {
  const selectedLocation = localStorage.getItem('FGJ.DY.Locator.Selected')
  if (selectedLocation) {
    return JSON.parse(selectedLocation)
  }
  const res = await getCurrentCityInfo()
  const model = {
    data: res.data,
    expireTime: new Date().getTime() + 3600 * 1000 * 24
  }
  localStorage.setItem('FGJ.DY.Locator.Selected', JSON.stringify(model))
  return model
}

export async function getCurrentCityInfo () {
  const currentLocation = localStorage.getItem('FGJ.DY.Locator.Current')
  if (currentLocation) {
    const a = JSON.parse(currentLocation)
    const currentTime = new Date().getTime()
    if (a.expireTime > currentTime) {
      localStorage.setItem(
        'FGJ.DY.Locator.Current',
        JSON.stringify({
          data: a.data,
          expireTime: new Date().getTime() + 3600 * 1000 * 24
        })
      )
      return a
    }
  }
  // let res = await getAMapCurrentPosition()
  // let params = {}
  // if (res.code === 0) {
  //   params = res.position
  // }
  // const res2 = await request('/movie/getRegion', {
  //   params: params
  // })
  const res2 = await request('/movie/getRegion')
  const model = {
    data: res2.data.city,
    expireTime: new Date().getTime() + 3600 * 1000 * 24
  }
  localStorage.setItem('FGJ.DY.Locator.Current', JSON.stringify(model))
  return model
}

export async function getAMapCurrentPosition () {
  return new Promise((resolve) => {
    AMapLoader.load({
      key: '956a1ae11b00143df18a13af69ea3at5', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.Geolocation'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      new AMap.Map('mapContainer')
      const geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, // 是否使用高精度定位，默认：true
        timeout: 5000, // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
        offset: [10, 20], // 定位按钮的停靠位置的偏移量
        zoomToAccuracy: true, //  定位按钮的排放位置,  RB表示右下
        buttonPosition: 'RB'
      })

      geolocation.getCurrentPosition(function (status, result) {
        if (status === 'complete') {
          onComplete(result)
        } else {
          onError(result)
        }
      })

      function onComplete (data) {
        // data是具体的定位信息
        console.log(data)
        resolve({
          code: 0,
          position: {
            latitude: data.position.lat,
            longitude: data.position.lng
          }
        })
      }

      function onError () {
        // 定位出错
        resolve({
          code: 404,
          message: '定位失败,定位系统失效'
        })
      }
    })
  })
}

export async function getAMapCurrentCity () {
  return new Promise((resolve) => {
    AMapLoader.load({
      key: '956a1ae11b00143df18a13af69ea3a68', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.CitySearch'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      new AMap.Map('mapContainer')
      const citySearch = new AMap.CitySearch()

      citySearch.getLocalCity(function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          // 查询成功，result即为当前所在城市信息
          resolve({
            code: 0,
            result
          })
        } else {
          resolve({
            code: 404,
            message: '定位失败,定位系统失效'
          })
        }
      })
    })
  })
}

export async function getCakeCurrentCityInfo () {
  const currentLocation = localStorage.getItem('DGSS.CITY')
  if (currentLocation) {
    return JSON.parse(currentLocation)
  }
  const res2 = await request('/dgss/getCurrentRegion')
  localStorage.setItem('DGSS.CITY', JSON.stringify(res2.data))
  return res2.data
}

export function getDistance (lat1, lng1, lat2, lng2) {
  const a = (lat1 * Math.PI) / 180
  const i = (lat2 * Math.PI) / 180
  const r = a - i
  const s = (lng1 * Math.PI) / 180 - (lng2 * Math.PI) / 180
  const c = 12756274 * Math.asin(Math.sqrt(Math.pow(Math.sin(r / 2), 2) + Math.cos(a) * Math.cos(i) * Math.pow(Math.sin(s / 2), 2)))
  return Math.round(10 * c) / 10
}
